import React from 'react'
const IconCart = props => (
  <svg width="24px" height="20px" viewBox="0 0 24 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1063.000000, -38.000000)">
        <g transform="translate(1063.000000, 38.000000)">
          <path
            d="M4.78532671,3.17460317 L22.1148934,3.17460317 C22.1425076,3.17460317 22.1648934,3.19698894 22.1648934,3.22460317 C22.1648934,3.22869126 22.164392,3.23276393 22.1634005,3.23672996 L20.6000094,9.49029444 C20.5949668,9.51046471 20.5779685,9.52540966 20.5573186,9.52782821 L7.08100253,11.1061974 C7.05677734,11.1090347 7.03404004,11.0939789 7.02719535,11.0705682 L4.73733586,3.23863449 C4.72958658,3.21212987 4.74479078,3.1843616 4.7712954,3.17661232 C4.77585336,3.17527969 4.78057793,3.17460317 4.78532671,3.17460317 Z"
            id="Rectangle"
            stroke="currentColor"
            strokeWidth="1.8"
          ></path>
          <path
            d="M0.5,1.58730159 L3.37325828,1.58730159 L3.37325828,1.58730159 L4.21796994,4.97328233 L5.06714085,7.80709493 C6.0190969,10.944571 6.76060969,13.2391537 7.2931169,14.6946767 C7.46459703,15.1639349 7.91125708,15.4761905 8.4111206,15.4761905 L19.2524689,15.4761905 C19.4819028,15.4761905 19.6818943,15.3200418 19.7375402,15.0974583 L19.8843656,14.5101567 C19.95134,14.2422592 19.7884596,13.970792 19.5205621,13.9038176 C19.4809018,13.8939026 19.4401752,13.8888889 19.3992943,13.8888889 L8.68952386,13.8888889 L8.68952386,13.8888889 L8.34994629,12.9130263 L8.103923,12.1778284 C7.5066314,10.3696752 6.74672411,7.91280357 5.82514767,4.80973612 L4.66137033,0.373135614 C4.60370232,0.153291225 4.40501473,-3.74817953e-16 4.17773263,0 L0.5,0 C0.223857625,5.07265313e-17 3.37169623e-14,0.223857625 3.37507799e-14,0.5 L3.37507799e-14,1.08730159 C3.37845976e-14,1.36344396 0.223857625,1.58730159 0.5,1.58730159 Z"
            id="Path-3"
            fill="currentColor"
            fillRule="nonzero"
          ></path>
          <circle fill="currentColor" cx="9.03679654" cy="18.1240981" r="1.2987013"></circle>
          <circle fill="currentColor" cx="18.3441558" cy="18.1240981" r="1.2987013"></circle>
        </g>
      </g>
    </g>
  </svg>
)

export default IconCart
