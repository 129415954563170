import React from 'react'
const IconArrowDown = props => (
  <svg {...props} width="15px" height="11px" viewBox="0 0 15 11" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g troke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-593.000000, -711.000000)" stroke="#404944">
        <path
          d="M600.49982,714.024615 L600.690708,714.103471 L600.308927,714.10346 L600.49982,714.024615 Z M600.49982,714.024615 L606.467544,711.559755 C607.098465,711.293327 607.760045,711.959221 607.373273,712.6285 L601.105953,721.135361 C600.81199,721.647664 600.239254,721.588379 599.93293,721.192499 L599.925755,721.182996 L593.593971,712.578573 C593.242166,711.957793 593.901352,711.29431 594.529496,711.558249 L600.49982,714.024615 Z"
          id="Stroke-1"
        ></path>
      </g>
    </g>
  </svg>
)

export default IconArrowDown
