import React from 'react'
const IconAdd = props => (
  <svg {...props} width="15px" height="15px" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(0.000000, -16.000000)">
        <g>
          <g transform="translate(0.000000, 16.000000)">
            <rect fill="#45A582" x="0" y="0" width="15" height="15" rx="1"></rect>
            <rect fill="#FFFFFF" x="7" y="4" width="1" height="7"></rect>
            <rect
              fill="#FFFFFF"
              transform="translate(7.500000, 7.500000) rotate(90.000000) translate(-7.500000, -7.500000) "
              x="7"
              y="4"
              width="1"
              height="7"
            ></rect>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default IconAdd
