import React from 'react'
const IconCopy = props => (
  <svg {...props} width="12px" height="16px" viewBox="0 0 12 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <polygon id="path-copy" points="0.5006 0.93 9.9026 0.93 9.9026 14 0.5006 14"></polygon>
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-6.000000, -16.000000)">
        <g transform="translate(0.000000, 12.000000)">
          <g transform="translate(6.000000, 4.000000)">
            <path
              d="M1.5002,10.7468 L1.5002,8.2468 L1.5002,4.6718 L1.5002,3.2068 L3.2062,1.4998 L4.6712,1.4998 L7.9022,1.4998 L9.4022,1.4998 L9.4022,0.4478 C9.4022,0.2008 9.2022,-0.0002 8.9552,-0.0002 L2.5852,-0.0002 L0.0002,2.5858 L0.0002,8.2468 C0.0002,9.5938 0.5392,10.3818 1.5342,11.1518 C1.5232,11.0158 1.5002,10.8928 1.5002,10.7468"
              id="Fill-1"
              fill="#45A582"
            ></path>
            <g transform="translate(2.000000, 1.569800)">
              <mask id="mask-copy" fill="white">
                <use href="#path-copy"></use>
              </mask>
              <path
                d="M8.4026,9.177 C8.4026,10.311 7.9816,10.658 5.6176,12.101 L5.2016,12.357 L4.7856,12.101 C2.4216,10.658 2.0006,10.311 2.0006,9.177 L2.0006,4.137 L3.7066,2.43 L8.4026,2.43 L8.4026,9.177 Z M9.4556,0.93 L3.0856,0.93 L0.5006,3.516 L0.5006,9.177 C0.5006,11.486 2.0426,12.156 4.8456,13.899 C5.0626,14.034 5.3396,14.034 5.5566,13.899 C8.3606,12.156 9.9026,11.486 9.9026,9.177 L9.9026,1.378 C9.9026,1.131 9.7026,0.93 9.4556,0.93 L9.4556,0.93 Z"
                id="Fill-3"
                fill="#45A582"
                mask="url(#mask-copy)"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default IconCopy
