import React from 'react'
const IconImage = props => (
  <svg {...props} width="12px" height="11px" viewBox="0 0 12 11" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-336.000000, -13.000000)">
        <g transform="translate(324.000000, 0.000000)">
          <rect opacity="0.499302455" x="0" y="0" width="36" height="36" rx="2"></rect>
          <g transform="translate(10.000000, 10.000000)">
            <rect x="0" y="0" width="16" height="16"></rect>
            <g strokeWidth="1" fillRule="evenodd" transform="translate(2.000000, 3.000000)">
              <rect stroke="currentColor" strokeWidth="1.5" x="0.75" y="0.75" width="10.5" height="9.5" rx="1"></rect>
              <polygon fill="currentColor" points="2 9 4 6.5 6 7.5 8 4 10 9"></polygon>
              <circle fill="currentColor" cx="4" cy="4" r="1"></circle>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default IconImage
