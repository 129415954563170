import React from 'react'
const IconStrikethrough = props => (
  <svg {...props} width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="16" height="16"></rect>
      <g transform="translate(3.321055, 3.730469)" fill="currentColor" fillRule="nonzero">
        <path d="M8.84203125,0 L8.84203125,2.50878906 L8.5959375,2.50878906 C8.45010344,1.93001013 8.2883212,1.51416142 8.11058594,1.26123047 C7.93285067,1.00829952 7.68903801,0.806641377 7.37914063,0.65625 C7.20596268,0.57421834 6.90290581,0.533203125 6.46996094,0.533203125 L5.77953125,0.533203125 L5.77953125,7.68359375 C5.77953125,8.15755445 5.80573542,8.45377545 5.85814453,8.57226562 C5.91055365,8.6907558 6.01309168,8.79443315 6.16576172,8.88330078 C6.31843175,8.97216841 6.52692576,9.01660156 6.79125,9.01660156 L7.09886719,9.01660156 L7.09886719,9.26953125 L2.24535156,9.26953125 L2.24535156,9.01660156 L2.55296875,9.01660156 C2.8218503,9.01660156 3.03831949,8.96875048 3.20238281,8.87304688 C3.32087299,8.80924447 3.41429653,8.69987057 3.48265625,8.54492188 C3.53278671,8.43554633 3.55785156,8.14843982 3.55785156,7.68359375 L3.55785156,0.533203125 L2.88792969,0.533203125 C2.26357761,0.533203125 1.81013162,0.665363262 1.52757813,0.9296875 C1.13109177,1.29882997 0.880443232,1.82519189 0.775625,2.50878906 L0.515859375,2.50878906 L0.515859375,0 L8.84203125,0 Z"></path>
        <polygon
          id="Decoration"
          points="2.18491891e-13 6.74023438 2.18491891e-13 5.40722656 8.85203125 5.40722656 8.85203125 6.74023438"
        ></polygon>
      </g>
    </g>
  </svg>
)

export default IconStrikethrough
