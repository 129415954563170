import React from 'react'
const IconTable = props => (
  <svg width="14" height="12" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.5 10.85a.65.65 0 01-.65.65H1.15a.65.65 0 01-.65-.65V1A.5.5 0 011 .5h12a.5.5 0 01.5.5v9.85zM3.5 8.5h-2v2h2v-2zm3 0h-2v2h2v-2zm3 0h-2v2h2v-2zm3 0h-2v2h2v-2zm-9-3h-2v2h2v-2zm3 0h-2v2h2v-2zm3 0h-2v2h2v-2zm3 0h-2v2h2v-2zm-9-3h-2v2h2v-2zm3 0h-2v2h2v-2zm3 0h-2v2h2v-2zm3 0h-2v2h2v-2z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
)

export default IconTable
