import React from 'react'
const IconClose = props => (
  <svg {...props} width="10px" height="10px" viewBox="0 0 10 10" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-560.000000, -36.000000)" fill="#45A582">
        <g id="Group-7" transform="translate(560.000000, 36.000000)">
          <rect
            id="Rectangle"
            transform="translate(5.000000, 5.000000) scale(-1, 1) rotate(45.000000) translate(-5.000000, -5.000000) "
            x="4"
            y="-1"
            width="2"
            height="12"
          ></rect>
          <rect
            id="Rectangle"
            transform="translate(5.000000, 5.000000) rotate(45.000000) translate(-5.000000, -5.000000) "
            x="4"
            y="-1"
            width="2"
            height="12"
          ></rect>
        </g>
      </g>
    </g>
  </svg>
)

export default IconClose
