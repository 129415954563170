import React from 'react'
const IconOrderedList = props => (
  <svg {...props} width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="16" height="16"></rect>
      <path
        d="M2.60546875,1.09375 L2.60546875,5.42578125 C2.60546875,5.75130371 2.61979152,5.95898393 2.6484375,6.04882812 C2.67708348,6.13867232 2.73697871,6.2122393 2.828125,6.26953125 C2.91927129,6.3268232 3.06510316,6.35546875 3.265625,6.35546875 L3.38671875,6.35546875 L3.38671875,6.5 L0.59765625,6.5 L0.59765625,6.35546875 L0.73828125,6.35546875 C0.964844883,6.35546875 1.1263016,6.32942734 1.22265625,6.27734375 C1.3190109,6.22526016 1.38411441,6.15364629 1.41796875,6.0625 C1.45182309,5.97135371 1.46875,5.75911625 1.46875,5.42578125 L1.46875,2.6796875 C1.46875,2.43489461 1.45703137,2.28190135 1.43359375,2.22070312 C1.41015613,2.1595049 1.36653678,2.10742209 1.30273438,2.06445312 C1.23893197,2.02148416 1.165365,2 1.08203125,2 C0.949218086,2 0.787761367,2.04166625 0.59765625,2.125 L0.52734375,1.984375 L2.4765625,1.09375 L2.60546875,1.09375 Z"
        id="1"
        fill="currentColor"
        fillRule="nonzero"
      ></path>
      <path
        d="M3.3125,14.5 L0.1953125,14.5 L0.1953125,14.4140625 C1.15104645,13.276036 1.73111877,12.5104186 1.93554688,12.1171875 C2.13997498,11.7239564 2.2421875,11.3398456 2.2421875,10.9648438 C2.2421875,10.6914049 2.15755293,10.4641936 1.98828125,10.2832031 C1.81900957,10.1022126 1.61198039,10.0117188 1.3671875,10.0117188 C0.966143828,10.0117188 0.654949023,10.2122376 0.43359375,10.6132812 L0.2890625,10.5625 C0.429688203,10.0624975 0.643227734,9.69270953 0.9296875,9.453125 C1.21614727,9.21354047 1.54687313,9.09375 1.921875,9.09375 C2.19010551,9.09375 2.43489473,9.15624937 2.65625,9.28125 C2.87760527,9.40625063 3.05078062,9.57747287 3.17578125,9.79492188 C3.30078188,10.0123709 3.36328125,10.2161449 3.36328125,10.40625 C3.36328125,10.7526059 3.26692805,11.1041649 3.07421875,11.4609375 C2.8111966,11.9427107 2.23698359,12.614579 1.3515625,13.4765625 L2.49609375,13.4765625 C2.77734516,13.4765625 2.96028604,13.4648439 3.04492188,13.4414062 C3.12955771,13.4179686 3.19921848,13.3782555 3.25390625,13.3222656 C3.30859402,13.2662758 3.38020789,13.1484384 3.46875,12.96875 L3.609375,12.96875 L3.3125,14.5 Z"
        id="2"
        fill="currentColor"
        fillRule="nonzero"
      ></path>
      <g transform="translate(5.500000, 2.500000)" fill="currentColor">
        <rect x="0" y="0" width="10" height="2" rx="0.5"></rect>
        <rect x="0" y="6" width="10" height="2" rx="0.5"></rect>
        <rect x="0" y="3" width="10" height="2" rx="0.5"></rect>
        <rect x="0" y="9" width="10" height="2" rx="0.5"></rect>
      </g>
    </g>
  </svg>
)

export default IconOrderedList
