import React from 'react'
const IconAddToCart = props => (
  <svg {...props} width="30px" height="25px" viewBox="0 0 30 25" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1035.000000, -359.000000)">
        <g transform="translate(1035.000000, 359.000000)">
          <g transform="translate(0.000000, 9.000000)" fill="currentColor">
            <path
              d="M3.90677064,1.85700025 L18.0547372,1.85700025 C18.4830814,1.85700025 18.8303231,2.2042419 18.8303231,2.63258619 C18.8264346,2.72758648 18.8264346,2.72758648 18.807166,2.82069341 L17.5308038,7.92614253 C17.4525849,8.23901808 17.1889114,8.47083994 16.8685961,8.50835581 L5.86644749,9.79694633 C5.49067309,9.84095767 5.13797855,9.60741567 5.03180558,9.24427561 L3.16234998,2.85023597 C3.04214532,2.4391038 3.27798867,2.00837019 3.68912085,1.88816553 C3.79646577,1.86224717 3.79646577,1.86224717 3.90677064,1.85700025 Z M4.83266014,3.3265315 L6.27777017,8.26919544 L16.2214372,7.10457611 L17.1659484,3.3265315 L4.83266014,3.3265315 Z"
              id="Rectangle"
              fillRule="nonzero"
            ></path>
            <path
              d="M0.5,1.29588294 L2.75394915,1.29588294 L2.75394915,1.29588294 L3.44357702,4.06021878 L4.13684546,6.3737611 C4.91402833,8.93521618 5.51940401,10.8085278 5.95414622,11.9968259 C6.09414367,12.3799312 6.45879973,12.6348586 6.86689142,12.6348586 L15.6461631,12.6348586 C15.875597,12.6348586 16.0755885,12.4787099 16.1312344,12.2561264 L16.2052051,11.9602435 C16.2721795,11.6923461 16.1092991,11.4208788 15.8414017,11.3539044 C15.8017414,11.3439894 15.7610148,11.3389757 15.7201339,11.3389757 L7.09418159,11.3389757 L7.09418159,11.3389757 L6.81694834,10.5422754 L6.61609339,9.94205521 C6.12846079,8.46586768 5.50806773,6.46006229 4.75568696,3.92669863 L3.82354171,0.373135614 C3.7658737,0.153291225 3.56718611,4.93182923e-16 3.33990401,0 L0.5,0 C0.223857625,5.07265313e-17 3.34394065e-14,0.223857625 3.37507799e-14,0.5 L3.37507799e-14,0.795882937 C3.37845976e-14,1.07202531 0.223857625,1.29588294 0.5,1.29588294 Z"
              id="Path-3"
              fillRule="nonzero"
            ></path>
            <circle cx="7.37769717" cy="14.796627" r="1.06026786"></circle>
            <circle cx="14.9762835" cy="14.796627" r="1.06026786"></circle>
          </g>
          <g transform="translate(14.000000, 0.000000)">
            <circle fill="#FFFFFF" cx="8" cy="8" r="8"></circle>
            <line
              x1="4.47907944"
              y1="7.52092056"
              x2="12.4790794"
              y2="7.52092056"
              stroke="currentColor"
              strokeWidth="2"
              transform="translate(8.000000, 7.520921) rotate(90.000000) translate(-8.000000, -7.520921) "
            ></line>
            <line x1="4" y1="8" x2="12" y2="8" stroke="currentColor" strokeWidth="2"></line>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default IconAddToCart
