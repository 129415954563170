import React from 'react'
const IconProfile = props => (
  <svg {...props} width="13px" height="13px" viewBox="0 0 13 13" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-907.000000, -32.000000)" stroke="currentColor">
        <g id="Profil" transform="translate(907.000000, 32.000000)">
          <path
            d="M9.56022897,6.29931926 C8.73656708,7.06397708 7.65254237,7.5 6.5,7.5 C5.3463221,7.5 4.26227525,7.06293985 3.43975287,6.29952753 C1.19934916,7.75256008 0.5,8.73072142 0.5,10.5964144 L0.5,12.2626067 C0.5,12.3236263 0.551953814,12.375 0.619047619,12.375 L12.3783053,12.375 C12.4467344,12.375 12.5,12.322307 12.5,12.2599881 L12.5,10.5964144 C12.5,8.73059176 11.8007177,7.7524444 9.56022897,6.29931926 Z"
            id="Combined-Shape"
          ></path>
          <circle id="Oval" cx="6.5" cy="3" r="2.5"></circle>
        </g>
      </g>
    </g>
  </svg>
)

export default IconProfile
