import React from 'react'
const IconSearch = props => (
  <svg {...props} width="13px" height="13px" viewBox="0 0 13 13" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-16.000000, -18.000000)">
        <g transform="translate(16.000000, 18.000000)">
          <circle stroke="currentColor" strokeWidth="1.2" cx="7.17157288" cy="5" r="4.4"></circle>
          <rect
            fill="currentColor"
            transform="translate(2.500000, 10.121320) rotate(-45.000000) translate(-2.500000, -10.121320) "
            x="0"
            y="9.62132034"
            width="5"
            height="1"
          ></rect>
        </g>
      </g>
    </g>
  </svg>
)

export default IconSearch
