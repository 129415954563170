import React from 'react'
const IconCartCheck = props => (
  <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-176.000000, -274.000000)">
        <g transform="translate(176.000000, 274.000000)">
          <circle stroke="currentColor" cx="11" cy="11" r="10.5"></circle>
          <path
            d="M9.10837568,13.9573925 C9.12973931,13.9983008 9.18252006,14.0125568 9.22210562,13.9877638 L15.9617039,8.14654553 C16.0440167,8.09571998 15.9811825,7.97175525 15.8907012,8.00584555 L9.16555482,11.7601175 L7.1127609,10.8750093 C7.04364325,10.844638 6.97389727,10.9177772 7.00971278,10.9834785 L9.10837568,13.9573925 Z"
            id="Fill-29"
            fill="currentColor"
          ></path>
        </g>
      </g>
    </g>
    <style jsx>{`
      .disabled {
        color: #d9dbda;
      }
    `}</style>
  </svg>
)

export default IconCartCheck
