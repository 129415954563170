import React from 'react'
const IconLinkChain = props => (
  <svg {...props} width="14px" height="13px" viewBox="0 0 14 13" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-398.000000, -23.000000)" stroke="#666D69" strokeWidth="1.5">
        <g transform="translate(398.000000, 22.000000)">
          <rect
            transform="translate(4.000000, 4.500000) rotate(45.000000) translate(-4.000000, -4.500000) "
            x="1.75"
            y="2.75"
            width="4.5"
            height="3.5"
            rx="1"
          ></rect>
          <rect
            transform="translate(10.000000, 10.500000) rotate(45.000000) translate(-10.000000, -10.500000) "
            x="7.75"
            y="8.75"
            width="4.5"
            height="3.5"
            rx="1"
          ></rect>
          <path d="M6,6.5 L8.12132034,8.62132034" id="Line" strokeLinecap="square"></path>
        </g>
      </g>
    </g>
  </svg>
)

export default IconLinkChain
