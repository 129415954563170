import React from 'react'
const IconMove = props => (
  <svg {...props} width="15px" height="15px" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <polygon id="moveIcon" points="0 0.25 9.403 0.25 9.403 13.3169907 0 13.3169907"></polygon>
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-5.000000, -4.000000)">
        <g transform="translate(5.000000, 3.000000)">
          <g transform="translate(0.000000, 0.750000)">
            <mask id="moveIconMask" fill="white">
              <use href="#moveIcon"></use>
            </mask>
            <g id="Clip-2"></g>
            <path
              d="M4.71,11.6709 L4.701,11.6759 L4.286,11.4209 C1.921,9.9779 1.5,9.6299 1.5,8.4969 L1.5,3.4569 L3.207,1.7499 L7.903,1.7499 L7.903,7.2499 L9.403,7.2499 L9.403,0.6979 C9.403,0.4509 9.202,0.2499 8.955,0.2499 L2.585,0.2499 L0,2.8359 L0,8.4969 C0,10.8059 1.542,11.4759 4.346,13.2179 C4.457,13.2879 4.584,13.3189 4.71,13.3169907 L4.71,11.6709 Z"
              id="Fill-1"
              fill="currentColor"
              mask="url(#moveIconMask)"
            ></path>
          </g>
          <path
            d="M13.75,10.75 L6.25,10.75 C5.974,10.75 5.75,10.526 5.75,10.25 L5.75,9.75 C5.75,9.474 5.974,9.25 6.25,9.25 L13.75,9.25 C14.026,9.25 14.25,9.474 14.25,9.75 L14.25,10.25 C14.25,10.526 14.026,10.75 13.75,10.75"
            fill="currentColor"
          ></path>
          <path
            d="M13.75,13.25 L6.25,13.25 C5.974,13.25 5.75,13.026 5.75,12.75 L5.75,12.25 C5.75,11.974 5.974,11.75 6.25,11.75 L13.75,11.75 C14.026,11.75 14.25,11.974 14.25,12.25 L14.25,12.75 C14.25,13.026 14.026,13.25 13.75,13.25"
            fill="currentColor"
          ></path>
          <path
            d="M13.75,15.75 L6.25,15.75 C5.974,15.75 5.75,15.526 5.75,15.25 L5.75,14.75 C5.75,14.474 5.974,14.25 6.25,14.25 L13.75,14.25 C14.026,14.25 14.25,14.474 14.25,14.75 L14.25,15.25 C14.25,15.526 14.026,15.75 13.75,15.75"
            fill="currentColor"
          ></path>
        </g>
      </g>
    </g>
  </svg>
)

export default IconMove
