import React from 'react'
const IconArrow = props => (
  <svg {...props} width="14px" height="12px" viewBox="0 0 14 12" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="square">
      <g transform="translate(-105.000000, -12.000000)" stroke="#FFFFFF" strokeWidth="1.5">
        <g transform="translate(106.000000, 13.000000)">
          <path
            d="M7.47921317,2.5 L12.3699486,2.5"
            transform="translate(9.869949, 2.500000) rotate(-315.000000) translate(-9.869949, -2.500000) "
          ></path>
          <path
            d="M7.47420742,7.50814972 L12.3820335,7.50814972"
            transform="translate(9.882034, 7.508150) scale(1, -1) rotate(-315.000000) translate(-9.882034, -7.508150) "
          ></path>
          <path d="M0.5,5.02092056 L12,5.02092056"></path>
        </g>
      </g>
    </g>
  </svg>
)

export default IconArrow
