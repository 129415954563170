import React from 'react'

const Magnifier = props => (
  <svg {...props} width="16" height="14" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <path id="magnifier" d="M0 .416h9.62V10H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M5.926 10.671c-.241.151-.508.316-.81.5l-.415.255-.415-.255C1.92 9.728 1.5 9.381 1.5 8.246v-5.04L3.207 1.5h4.696v1.913c.465-.205.973-.32 1.5-.368V.447A.448.448 0 008.955 0h-6.37L0 2.586v5.66c0 2.31 1.542 2.98 4.346 4.722a.675.675 0 00.71 0c.745-.463 1.394-.848 1.955-1.21a4.865 4.865 0 01-1.085-1.087"
        fill="currentColor"
      />
      <g transform="translate(6 3.584)">
        <mask id="magnifier-mask" fill="#fff">
          <use href="#magnifier" />
        </mask>
        <path
          d="M1.7 4.262c0-1.184.962-2.146 2.146-2.146s2.146.962 2.146 2.146a2.148 2.148 0 01-2.146 2.146A2.148 2.148 0 011.7 4.262m7.773 4.39L7.105 6.282a3.81 3.81 0 00.587-2.02 3.846 3.846 0 10-3.846 3.845 3.82 3.82 0 002.068-.612l2.357 2.357a.499.499 0 00.707 0l.495-.495a.499.499 0 000-.707"
          fill="currentColor"
          mask="url(#magnifierMask)"
        />
      </g>
    </g>
  </svg>
)

export default Magnifier
