import React from 'react'
const IconEdit = props => (
  <svg {...props} width="15px" height="16px" viewBox="0 0 15 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <polygon id="path-edit" points="0.0005 -0.0003 15.01475 -0.0003 15.01475 15.7536639 0.0005 15.7536639"></polygon>
    </defs>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Action/Edit/Edit" transform="translate(-5.000000, -16.000000)">
        <g id="Icon/Green/Edit" transform="translate(0.000000, 12.000000)">
          <g id="Group-3" transform="translate(5.000000, 4.000000)">
            <mask id="mask-edit" fill="white">
              <use href="#path-edit"></use>
            </mask>
            <g id="Clip-2"></g>
            <path
              d="M3.5685,12.4906 C1.2585,11.0806 0.0005,10.3326 0.0005,8.2466 L0.0005,2.5856 L2.5855,-0.0004 L8.9555,-0.0004 C9.2025,-0.0004 9.4025,0.2006 9.4025,0.4476 L9.4025,6.1536 L7.9025,7.6536 L7.9025,1.4996 L3.2065,1.4996 L1.5005,3.2066 L1.5005,8.2466 C1.5005,9.0436 1.7275,9.4366 2.5265,10.0346 C2.8005,10.2406 3.1355,10.4626 3.6235,10.7646 C3.7195,10.8246 4.0265,11.0126 4.3475,11.2086 L3.6945,11.8626 L3.5685,12.4906 Z M7.4435,15.1836 L4.6435,15.7436 C4.3735,15.7986 4.1095,15.6226 4.0555,15.3516 C4.0425,15.2866 4.0425,15.2196 4.0555,15.1556 L4.6155,12.3556 L12.0405,4.9306 C12.2355,4.7356 12.5515,4.7356 12.7475,4.9306 L14.8685,7.0526 C15.0635,7.2476 15.0635,7.5636 14.8685,7.7596 L7.4435,15.1836 Z M13.1005,7.4056 L12.3935,6.6986 L5.9975,13.0946 L5.8205,13.9786 L6.7045,13.8026 L13.1005,7.4056 Z"
              id="Fill-1"
              fill="#45A582"
              mask="url(#mask-edit)"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default IconEdit
