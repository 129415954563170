import React from 'react'
const IconClients = props => (
  <svg {...props} width="19px" height="13px" viewBox="0 0 19 13" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <path
        d="M26.2876,23.6602 C28.4909929,25.04395 29.7106077,26.1217804 29.7949125,28.3444515 L29.7996,28.5962 L29.7996,30.2602 C29.7996,30.5683818 29.5698479,30.8244975 29.2703739,30.8685171 L29.1786,30.8752 L24.8766,30.8752 C24.9351,30.7327 24.9750375,30.5806375 24.99135,30.4215438 L24.9996,30.2602 L24.9996,29.8752 L28.7996,29.8752 L28.7996,28.5962 C28.7996,26.9362 28.1576,26.0982 26.3996,24.9222 C25.8016,25.3952 25.0906,25.6932 24.3476,25.8432 C24.1746,25.5372 23.9656,25.2412 23.7136,24.9552 C24.7316,24.8482 25.6416,24.3842 26.2876,23.6602 Z M20.488,23.66 C22.772,25.095 24,26.201 24,28.596 L24,28.596 L24,30.26 C24,30.6 23.722,30.875 23.378,30.875 L23.378,30.875 L11.619,30.875 C11.277,30.875 11,30.601 11,30.263 L11,30.263 L11,28.596 C11,26.201 12.228,25.095 14.513,23.66 C15.245,24.482 16.312,25 17.5,25 C18.688,25 19.755,24.482 20.488,23.66 Z M20.6,24.922 C18.847,26.307 16.154,26.307 14.4,24.923 C12.643,26.098 12,26.937 12,28.596 L12,28.596 L12,29.875 L23,29.875 L23,28.596 C23,26.936 22.357,26.098 20.6,24.922 Z M17.5,18 C19.157,18 20.5,19.343 20.5,21 C20.5,22.657 19.157,24 17.5,24 C15.843,24 14.5,22.657 14.5,21 C14.5,19.343 15.843,18 17.5,18 Z M23.2998,18 C24.9568,18 26.2998,19.343 26.2998,21 C26.2998,22.657 24.9568,24 23.2998,24 C22.3688,24 21.5488,23.567 20.9978,22.902 C21.2018,22.529 21.3538,22.126 21.4298,21.692 C21.7128,22.454 22.4398,23 23.2998,23 C24.4038,23 25.2998,22.104 25.2998,21 C25.2998,19.896 24.4038,19 23.2998,19 C22.4398,19 21.7128,19.546 21.4298,20.308 C21.3538,19.874 21.2018,19.471 20.9978,19.098 C21.5488,18.433 22.3688,18 23.2998,18 Z M17.5,19 C16.395,19 15.5,19.896 15.5,21 C15.5,22.104 16.395,23 17.5,23 C18.604,23 19.5,22.104 19.5,21 C19.5,19.896 18.604,19 17.5,19 Z"
        id="path-clients"
      ></path>
    </defs>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Elements/Icon/Menu/Kunden" transform="translate(-11.000000, -18.000000)">
        <mask id="mask-2" fill="white">
          <use href="#path-clients"></use>
        </mask>
        <use id="Mask" fill="#404944" href="#path-clients"></use>
      </g>
    </g>
  </svg>
)

export default IconClients
