import React from 'react'
const IconOrders = props => (
  <svg width="19px" height="19px" viewBox="0 0 19 19" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <path
        d="M26.381,14 C26.723,14 27,14.277 27,14.619 L27,14.619 L27,23.014 C26.651,23.091 26.318,23.206 26,23.351 L26,23.351 L26,15 L17.988,15 L15,17.989 L15,25.401 C15,27.594 16.049,28.338 19.367,30.362 C19.737,30.589 20.127,30.826 20.536,31.081 C20.873,30.826 21.262,30.589 21.633,30.362 C22.246,29.989 22.754,29.668 23.216,29.366 C23.311,29.698 23.436,30.013 23.593,30.313 C22.84,30.798 21.984,31.313 20.992,31.929 C20.691,32.116 20.308,32.116 20.008,31.929 C16.132,29.52 14,28.594 14,25.402 L14,25.402 L14,17.575 L17.574,14 Z M28.0903,23.8903 C28.3303,23.8903 28.5623,23.9213 28.7923,23.9603 C28.8573,23.9733 28.9233,23.9803 28.9873,23.9963 C29.1623,24.0353 29.3293,24.0923 29.4943,24.1533 C29.6103,24.1973 29.7253,24.2433 29.8363,24.2953 L29.8363,24.2953 L29.4833,25.2373 C29.0623,25.0223 28.5933,24.8903 28.0903,24.8903 C26.3863,24.8903 25.0003,26.2763 25.0003,27.9793 C25.0003,29.6843 26.3863,31.0693 28.0903,31.0693 C29.7933,31.0693 31.1793,29.6843 31.1793,27.9793 C31.1793,27.4763 31.0473,27.0073 30.8323,26.5863 L30.8323,26.5863 L31.7743,26.2333 C31.8273,26.3443 31.8733,26.4593 31.9163,26.5763 C31.9773,26.7413 32.0343,26.9073 32.0743,27.0823 C32.0893,27.1463 32.0963,27.2133 32.1093,27.2783 C32.1483,27.5073 32.1793,27.7383 32.1793,27.9783 L32.1793,27.9788 C32.1793,29.3923 31.4643,30.6363 30.3763,31.3713 C29.7233,31.8123 28.9363,32.0693 28.0903,32.0693 C25.8313,32.0693 24.0003,30.2383 24.0003,27.9793 C24.0003,27.4153 24.1143,26.8773 24.3213,26.3883 C24.5283,25.8983 24.8273,25.4573 25.1983,25.0883 C25.9383,24.3483 26.9603,23.8903 28.0903,23.8903 Z"
        id="IconOrdersPath"
      ></path>
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-14.000000, -14.000000)">
        <mask fill="white">
          <use href="#IconOrdersPath"></use>
        </mask>
        <use fill="currentColor" href="#IconOrdersPath"></use>
      </g>
    </g>
  </svg>
)

export default IconOrders
