import React from 'react'
const IconDashboard = props => (
  <svg {...props} width="13px" height="13px" viewBox="0 0 13 13" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-35.000000, -151.000000)" fill="#666D69" fillRule="nonzero">
        <g id="Medien-&amp;-Kategorien" transform="translate(35.000000, 133.000000)">
          <g id="Group" transform="translate(0.000000, 18.000000)">
            <path
              d="M1,0 L5,0 C5.55228475,0 6,0.44771525 6,1 L6,5 C6,5.55228475 5.55228475,6 5,6 L1,6 C0.44771525,6 0,5.55228475 0,5 L0,1 C0,0.44771525 0.44771525,0 1,0 Z M1,1 L1,5 L5,5 L5,1 L1,1 Z"
              id="Rectangle-Copy-4"
            ></path>
            <path
              d="M1,7 L5,7 C5.55228475,7 6,7.44771525 6,8 L6,12 C6,12.5522847 5.55228475,13 5,13 L1,13 C0.44771525,13 0,12.5522847 0,12 L0,8 C0,7.44771525 0.44771525,7 1,7 Z M1,8 L1,12 L5,12 L5,8 L1,8 Z"
              id="Rectangle-Copy-5"
            ></path>
            <path
              d="M8,0 L12,0 C12.5522847,0 13,0.44771525 13,1 L13,5 C13,5.55228475 12.5522847,6 12,6 L8,6 C7.44771525,6 7,5.55228475 7,5 L7,1 C7,0.44771525 7.44771525,0 8,0 Z M8,1 L8,5 L12,5 L12,1 L8,1 Z"
              id="Rectangle-Copy-6"
            ></path>
            <path
              d="M8,7 L12,7 C12.5522847,7 13,7.44771525 13,8 L13,12 C13,12.5522847 12.5522847,13 12,13 L8,13 C7.44771525,13 7,12.5522847 7,12 L7,8 C7,7.44771525 7.44771525,7 8,7 Z M8,8 L8,12 L12,12 L12,8 L8,8 Z"
              id="Rectangle-Copy-7"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default IconDashboard
