import React from 'react'
const IconUnderline = props => (
  <svg {...props} width="11px" height="12px" viewBox="0 0 11 12" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-84.000000, -13.000000)" fill="currentColor" fillRule="nonzero">
        <g transform="translate(72.000000, 0.000000)">
          <g transform="translate(10.000000, 10.000000)">
            <g transform="translate(2.944824, 3.730469)">
              <path d="M0.328125,0 L5.11328125,0 L5.11328125,0.252929688 L4.87402344,0.252929688 C4.5139956,0.252929688 4.266765,0.290526968 4.13232422,0.365722656 C3.99788344,0.440918345 3.90218127,0.544595693 3.84521484,0.676757812 C3.78824841,0.808919932 3.75976562,1.13932027 3.75976562,1.66796875 L3.75976562,6.20703125 C3.75976562,7.03646248 3.82242776,7.58561063 3.94775391,7.85449219 C4.07308005,8.12337374 4.27929544,8.3466788 4.56640625,8.52441406 C4.85351706,8.70214933 5.21581813,8.79101562 5.65332031,8.79101562 C6.1546249,8.79101562 6.58186672,8.67822378 6.93505859,8.45263672 C7.28825046,8.22704965 7.55257074,7.91601761 7.72802734,7.51953125 C7.90348395,7.12304489 7.99121094,6.43262211 7.99121094,5.44824219 L7.99121094,1.66796875 C7.99121094,1.25325313 7.9479171,0.957032139 7.86132812,0.779296875 C7.77473915,0.601561611 7.66536524,0.478515967 7.53320312,0.41015625 C7.328125,0.305338018 7.03873885,0.252929688 6.66503906,0.252929688 L6.66503906,0 L9.87109375,0 L9.87109375,0.252929688 L9.6796875,0.252929688 C9.41992058,0.252929688 9.20345139,0.305338018 9.03027344,0.41015625 C8.85709549,0.514974482 8.73177122,0.672199473 8.65429688,0.881835938 C8.59505179,1.02767 8.56542969,1.28971165 8.56542969,1.66796875 L8.56542969,5.18847656 C8.56542969,6.27767472 8.49365306,7.06379967 8.35009766,7.546875 C8.20654225,8.02995033 7.85677361,8.47428183 7.30078125,8.87988281 C6.74478889,9.2854838 5.98600741,9.48828125 5.02441406,9.48828125 C4.22232672,9.48828125 3.60254125,9.38118597 3.16503906,9.16699219 C2.56803087,8.87532406 2.14648561,8.50162988 1.90039062,8.04589844 C1.65429564,7.59016699 1.53125,6.97721739 1.53125,6.20703125 L1.53125,1.66796875 C1.53125,1.13476296 1.5016279,0.803223306 1.44238281,0.673339844 C1.38313772,0.543456382 1.28059969,0.439779033 1.13476562,0.362304687 C0.988931562,0.284830342 0.720054043,0.248372373 0.328125,0.252929688 L0.328125,0 Z"></path>
              <path
                d="M3.03597001,10.7939453 L2.18491891e-13,10.7939453 L2.18491891e-13,9.4609375 L3.03597001,9.4609375 L3.03597001,10.7939453 Z M6.94165101,10.7939453 L6.94165101,9.4609375 L9.80222656,9.4609375 L9.80222656,10.7939453 L6.94165101,10.7939453 Z"
                id="Decoration"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default IconUnderline
