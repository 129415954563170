import React from 'react'
const IconDelete = props => (
  <svg {...props} width="13px" height="16px" viewBox="0 0 13 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-6.000000, -16.000000)" fill="currentColor">
        <g transform="translate(0.000000, 12.000000)">
          <g transform="translate(6.000000, 4.000000)">
            <path
              d="M11.0607,12.5001 L12.6517,10.9091 C12.8467,10.7131 12.8467,10.3971 12.6517,10.2021 L12.2977,9.8481 C12.1027,9.6531 11.7867,9.6531 11.5907,9.8481 L9.9997,11.4391 L8.4087,9.8481 C8.2137,9.6531 7.8967,9.6531 7.7017,9.8481 L7.3487,10.2021 C7.1527,10.3971 7.1527,10.7131 7.3487,10.9091 L8.9387,12.5001 L7.3487,14.0911 C7.1527,14.2861 7.1527,14.6031 7.3487,14.7981 L7.7017,15.1511 C7.8967,15.3471 8.2137,15.3471 8.4087,15.1511 L9.9997,13.5611 L11.5907,15.1511 C11.7867,15.3471 12.1027,15.3471 12.2977,15.1511 L12.6517,14.7981 C12.8467,14.6031 12.8467,14.2861 12.6517,14.0911 L11.0607,12.5001 Z"
              id="Fill-1"
            ></path>
            <path
              d="M5.5001,12.5001 C5.5001,12.3291 5.5321,12.1661 5.5501,12.0001 L2.5001,12.0001 L2.5001,3.0001 L8.5001,3.0001 L8.5001,8.2751 C8.9711,8.1081 9.4711,8.0001 10.0001,8.0001 L10.0001,3.0001 L10.5001,3.0001 C10.7761,3.0001 11.0001,2.7761 11.0001,2.5001 L11.0001,2.0001 C11.0001,1.7241 10.7761,1.5001 10.5001,1.5001 L0.5001,1.5001 C0.2241,1.5001 0.0001,1.7241 0.0001,2.0001 L0.0001,2.5001 C0.0001,2.7761 0.2241,3.0001 0.5001,3.0001 L1.0001,3.0001 L1.0001,13.0001 C1.0001,13.2761 1.2241,13.5001 1.5001,13.5001 L5.6201,13.5001 C5.5471,13.1771 5.5001,12.8451 5.5001,12.5001"
              id="Fill-3"
            ></path>
            <path
              d="M8.0001,0.5001 C8.0001,0.2241 7.7761,0.0001 7.5001,0.0001 L3.5001,0.0001 C3.2241,0.0001 3.0001,0.2241 3.0001,0.5001 L3.0001,1.0001 L8.0001,1.0001 L8.0001,0.5001 Z"
              id="Fill-5"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default IconDelete
