import React from 'react'
const IconCheckedShield = props => (
  <svg {...props} width="18px" height="19px" viewBox="0 0 18 19" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <path
        d="M26.3813,15.0003 C26.7233,15.0003 26.9993,15.2773 26.9993,15.6183 L26.9993,15.6183 L26.9993,22.0793 C26.6583,22.1283 26.3233,22.1973 25.9993,22.2933 L25.9993,22.2933 L25.9993,16.0003 L17.9883,16.0003 L14.9993,18.9893 L14.9993,26.4013 C14.9993,28.5933 16.0493,29.3383 19.3673,31.3623 C19.7373,31.5883 20.1263,31.8263 20.5363,32.0803 C20.8203,31.8663 21.1413,31.6653 21.4573,31.4713 C21.5773,31.7873 21.7133,32.0953 21.8753,32.3873 C21.5863,32.5643 21.3043,32.7353 20.9923,32.9293 C20.6913,33.1163 20.3083,33.1163 20.0083,32.9293 C16.1323,30.5203 13.9993,29.5933 13.9993,26.4013 L13.9993,26.4013 L13.9993,18.5753 L17.5743,15.0003 Z M30.3721,23.3254 L31.1501,23.9534 L26.1251,30.1804 C26.0381,30.2884 25.9101,30.3554 25.7711,30.3654 C25.7591,30.3664 25.7481,30.3664 25.7351,30.3664 C25.6101,30.3664 25.4881,30.3194 25.3961,30.2334 L22.8891,27.9144 L23.5681,27.1794 L25.6821,29.1364 L30.3721,23.3254 Z"
        id="checkedShield-1"
      ></path>
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-14.000000, -15.000000)">
        <mask id="mask-2" fill="white">
          <use href="#checkedShield-1"></use>
        </mask>
        <use id="Mask" fill="#404944" href="#checkedShield-1"></use>
      </g>
    </g>
  </svg>
)

export default IconCheckedShield
