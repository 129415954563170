import React from 'react'
const IconUnorderedList = props => (
  <svg {...props} width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="16" height="16"></rect>
      <g transform="translate(2.000000, 2.500000)" fill="currentColor">
        <rect x="0" y="0" width="2" height="2" rx="0.5"></rect>
        <rect x="0" y="6" width="2" height="2" rx="0.5"></rect>
        <rect x="0" y="3" width="2" height="2" rx="0.5"></rect>
        <rect x="0" y="9" width="2" height="2" rx="0.5"></rect>
        <rect x="3" y="0" width="9" height="2" rx="0.5"></rect>
        <rect x="3" y="6" width="9" height="2" rx="0.5"></rect>
        <rect x="3" y="3" width="9" height="2" rx="0.5"></rect>
        <rect x="3" y="9" width="9" height="2" rx="0.5"></rect>
      </g>
    </g>
  </svg>
)

export default IconUnorderedList
