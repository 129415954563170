import React from 'react'
const IconDownload = props => (
  <svg {...props} width="11px" height="15px" viewBox="0 0 11 15" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-220.000000, -10.000000)">
        <g transform="translate(220.000000, 10.000000)">
          <g
            transform="translate(5.500000, 7.000000) rotate(90.000000) translate(-5.500000, -7.000000) translate(-1.000000, 2.000000)"
            stroke="currentColor"
            strokeLinecap="square"
            strokeWidth="1.5"
          >
            <path
              d="M7.47921317,2.5 L12.3699486,2.5"
              id="Line"
              transform="translate(9.869949, 2.500000) rotate(-315.000000) translate(-9.869949, -2.500000) "
            ></path>
            <path
              d="M7.47420742,7.50814972 L12.3820335,7.50814972"
              id="Line"
              transform="translate(9.882034, 7.508150) scale(1, -1) rotate(-315.000000) translate(-9.882034, -7.508150) "
            ></path>
            <path d="M0.5,5.02092056 L12,5.02092056" id="Line-Copy"></path>
          </g>
          <rect fill="currentColor" x="0" y="13.2" width="11" height="1.5"></rect>
        </g>
      </g>
    </g>
  </svg>
)

export default IconDownload
