import React from 'react'
const IconItalic = props => (
  <svg {...props} width="8px" height="10px" viewBox="0 0 8 10" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-50.000000, -13.000000)" fill="currentColor" fillRule="nonzero">
        <g transform="translate(36.000000, 0.000000)">
          <g transform="translate(10.000000, 10.000000)">
            <path
              d="M7.21044922,3.98339844 L7.28564453,3.73046875 L11.8110352,3.73046875 L11.722168,3.98339844 C11.3120097,3.98339844 10.998699,4.07454336 10.7822266,4.25683594 C10.5657541,4.43912852 10.3754891,4.81965856 10.2114258,5.3984375 L8.51611328,11.3320312 C8.39306579,11.7513042 8.33154297,12.0315748 8.33154297,12.1728516 C8.33154297,12.3369149 8.39534441,12.4645178 8.52294922,12.5556641 C8.68701254,12.6741542 8.99690527,12.7379557 9.45263672,12.7470703 L9.38427734,13 L4.78369141,13 L4.85888672,12.7470703 C5.31917548,12.7470703 5.65641169,12.6593433 5.87060547,12.4838867 C6.08479925,12.3084301 6.27848221,11.9244821 6.45166016,11.3320312 L8.16064453,5.3984375 C8.26546276,5.03385234 8.31787109,4.75813896 8.31787109,4.57128906 C8.31787109,4.40266843 8.25406965,4.27050829 8.12646484,4.17480469 C7.99886004,4.07910108 7.69352455,4.01529964 7.21044922,3.98339844 Z"
              id="I"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default IconItalic
