import React from 'react'
const IconWorld = props => (
  <svg {...props} width="17px" height="17px" viewBox="0 0 17 17" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-34.000000, -293.000000)" fill="#666D69" fillRule="nonzero">
        <g id="Website" transform="translate(35.000000, 277.000000)">
          <g id="Group-7" transform="translate(0.000000, 17.000000)">
            <path
              d="M7.84753314,-0.442532394 L8.08220468,-0.432428648 L8.20809132,-0.234122393 C9.66470568,2.06044792 10.45,4.72275339 10.45,7.5 C10.45,10.2772466 9.66470568,12.9395521 8.20809132,15.2341224 L8.08220468,15.4324286 L7.84753314,15.4425324 C7.73200443,15.4475065 7.61614236,15.45 7.5,15.45 C3.10933624,15.45 -0.45,11.8906638 -0.45,7.5 C-0.45,3.10933624 3.10933624,-0.45 7.5,-0.45 C7.61614236,-0.45 7.73200443,-0.447506465 7.84753314,-0.442532394 Z M7.5,0.45 C3.60639251,0.45 0.45,3.60639251 0.45,7.5 C0.45,11.3936075 3.60639251,14.55 7.5,14.55 C7.52472356,14.55 7.5494312,14.55 7.57412232,14.5496189 C8.85915093,12.4447334 9.55,10.0239507 9.55,7.5 C9.55,4.97604926 8.85915093,2.5552666 7.57412232,0.450381097 C7.5494312,0.45 7.52472356,0.45 7.5,0.45 Z"
              id="Combined-Shape-Copy"
            ></path>
            <path
              d="M12.8475331,-0.442532394 L13.0822047,-0.432428648 L13.2080913,-0.234122393 C14.6647057,2.06044792 15.45,4.72275339 15.45,7.5 C15.45,10.2772466 14.6647057,12.9395521 13.2080913,15.2341224 L13.0822047,15.4324286 L12.8475331,15.4425324 C12.7320044,15.4475065 12.6161424,15.45 12.5,15.45 C8.10933624,15.45 4.55,11.8906638 4.55,7.5 C4.55,3.10933624 8.10933624,-0.45 12.5,-0.45 C12.6161424,-0.45 12.7320044,-0.447506465 12.8475331,-0.442532394 Z M12.5,0.45 C8.60639251,0.45 5.45,3.60639251 5.45,7.5 C5.45,11.3936075 8.60639251,14.55 12.5,14.55 C12.5247236,14.55 12.5494312,14.55 12.5741223,14.5496189 C13.8591509,12.4447334 14.55,10.0239507 14.55,7.5 C14.55,4.97604926 13.8591509,2.5552666 12.5741223,0.450381097 C12.5494312,0.45 12.5247236,0.45 12.5,0.45 Z"
              id="Combined-Shape-Copy"
              transform="translate(10.000000, 7.500000) scale(-1, 1) translate(-10.000000, -7.500000) "
            ></path>
            <polygon id="Line" points="-0.45 7.95 15.45 7.95 15.45 7.05 -0.45 7.05"></polygon>
            <polygon id="Line-Copy" points="1.05 4.45 13.95 4.45 13.95 3.55 1.05 3.55"></polygon>
            <polygon id="Line-Copy-2" points="1.05 11.45 13.95 11.45 13.95 10.55 1.05 10.55"></polygon>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default IconWorld
