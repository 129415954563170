import React from 'react'
const IconBookmark = props => (
  <svg {...props} width="16px" height="22px" viewBox="0 0 16 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <path
        d="M29.667,25.6881 C29.851,25.6881 30,25.8381 30,26.0221 L30,26.0221 L30,35.3281 C26.5,31.8131 30,35.3131 26.5,31.8131 C23,35.3131 26.5,31.8131 23,35.3281 L23,35.3281 L23,26.1881 C23,25.9121 23.224,25.6881 23.5,25.6881 L23.5,25.6881 Z M29,26.6881 L24,26.6881 L24,32.9031 C24.008,32.8941 24.014,32.8881 24.018,32.8841 L24.018,32.8841 L24.04,32.9051 C24.2528,32.6555 24.62112,32.27982 25.260672,31.638796 L26.5,30.3991 L27.3964136,31.2953527 C28.2517867,32.1502156 28.7117333,32.6138333 28.96,32.9041 L28.96,32.9041 L28.982,32.8841 C28.986,32.8881 28.992,32.8941 29,32.9031 L29,32.9031 L29,26.6881 Z M26.381,13.9995 C26.723,13.9995 27,14.2775 27,14.6185 L27,14.6185 L27,24.6885 L26,24.6885 L26,14.9995 L17.988,14.9995 L15,17.9885 L15,25.4015 C15,27.5935 16.05,28.3375 19.367,30.3625 C19.737,30.5885 20.127,30.8255 20.536,31.0805 C20.873,30.8255 21.263,30.5885 21.633,30.3625 C21.767,30.2815 21.874,30.2135 22,30.1355 L22,30.1355 L22,31.3105 C21.673,31.5105 21.348,31.7085 20.992,31.9295 C20.691,32.1165 20.309,32.1165 20.008,31.9295 C16.132,29.5205 14,28.5935 14,25.4015 L14,25.4015 L14,17.5745 L17.574,13.9995 Z"
        id="bookmark-1"
      ></path>
    </defs>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Elements/Icon/Menu/Favoriten" transform="translate(-14.000000, -14.000000)">
        <mask id="mask-2" fill="white">
          <use href="#bookmark-1"></use>
        </mask>
        <use id="Mask" fill="#404944" href="#bookmark-1"></use>
      </g>
    </g>
  </svg>
)

export default IconBookmark
