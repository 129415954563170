import React from 'react'
const IconAdmins = props => (
  <svg {...props} width="17px" height="20px" viewBox="0 0 17 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <path
        d="M28.619,26.0001 C28.83,26.0001 29,26.1701 29,26.3811 L29,26.3811 L29,32.0161 C29,33.9801 27.688,34.5511 25.303,36.0341 C25.118,36.1481 24.882,36.1481 24.697,36.0341 C22.312,34.5511 21,33.9801 21,32.0161 L21,32.0161 L21,28.2001 L23.2,26.0001 Z M28,27.0001 L23.614,27.0001 L22,28.6141 L22,32.0161 C22,32.7881 22.239,33.2011 22.984,33.7591 C23.226,33.9391 23.516,34.1321 23.938,34.3931 C24.066,34.4741 24.638,34.8231 25,35.0451 C25.362,34.8231 25.934,34.4741 26.063,34.3931 C26.484,34.1321 26.774,33.9391 27.016,33.7591 C27.761,33.2011 28,32.7881 28,32.0161 L28,32.0161 L28,27.0001 Z M15.513,22.66 C15.745,22.921 16.012,23.151 16.305,23.345 C15.678,23.732 15.229,24.029 14.846,24.312 C13.5559,25.26675 13.050975,26.0671725 13.0037078,27.3837348 L13,27.596 L13,28.875 L20,28.875 L20,29.875 L12.619,29.875 C12.3080909,29.875 12.0509008,29.6485537 12.0067085,29.3534373 L12,29.263 L12,27.596 C12,25.201 13.228,24.095 15.513,22.66 Z M21.4868,22.66 C22.6372167,23.3823333 23.5086611,24.0265208 24.0995928,24.7889774 L24.2538,25 L22.9658,25 C22.7378,24.773 22.4718,24.547 22.1538,24.312 C21.7718,24.029 21.3218,23.732 20.6948,23.344 C20.9878,23.151 21.2548,22.921 21.4868,22.66 Z M18.5,17 C20.157,17 21.5,18.343 21.5,20 C21.5,21.657 20.157,23 18.5,23 C16.843,23 15.5,21.657 15.5,20 C15.5,18.343 16.843,17 18.5,17 Z M18.5,18 C17.396,18 16.5,18.896 16.5,20 C16.5,21.104 17.396,22 18.5,22 C19.604,22 20.5,21.104 20.5,20 C20.5,18.896 19.604,18 18.5,18 Z"
        id="path-admins"
      ></path>
    </defs>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Elements/Icon/Menu/Benutzer" transform="translate(-12.000000, -17.000000)">
        <mask id="mask-2" fill="white">
          <use href="#path-admins"></use>
        </mask>
        <use id="Mask" fill="#404944" href="#path-admins"></use>
      </g>
    </g>
  </svg>
)

export default IconAdmins
