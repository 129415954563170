const Strings = {
  de: {
    title: 'Safetychecks',
    notYetImplemented: 'Frontend bisher noch nicht implementiert',
    components: {
      admin: {
        user: 'Benutzer',
      },
      answer: {
        annotation: 'Anmerkung',
        answer: 'Antwort',
        confirm: 'Bestätigen',
        edit: 'Bearbeiten',
        info: 'Info',
        noAnswerPossibility: 'Keine Antwortmöglichkeit',
        no: 'Nein',
        save: 'Speichern',
        yes: 'Ja',
      },
      check: {
        active: 'aktiv',
        addCheck: 'Assistent hinzufügen',
        addDynamicField: 'Doppelfelder hinzufügen',
        deleteDynamicField: 'Doppelfeld löschen',
        attachements: 'Angehängte Dokumente',
        blockTitle: 'Abfrageblocktitel',
        cancel: 'Assistent verwerfen',
        categories: 'Kategorien',
        confirmActivation: 'Assistenten wirklich aktivieren?',
        confirmDeactivation: 'Assistenten wirklich deaktivieren?',
        confirmDeleteBlock: 'Abfrageblock wirklich löschen?',
        confirmDeleteOption: 'Option wirklich löschen?',
        confirmDeleteCheck: 'Assistenten wirklich löschen?',
        confirmDeleteQuestion: 'Abfrage wirklich löschen?',
        deleteBlock: 'Block löschen',
        copyBlock: 'Block duplizieren',
        description: 'Beschreibung zum Assistenten',
        documentTitle: 'Titel für Ausgabe',
        documentTitleHead: 'Dokumententitel',
        dynamicField: 'Dynamisches Feld',
        edit: 'Assistent bearbeiten',
        enterBlockDescription: 'Text eingeben',
        evaluation: 'Vollständige Auswertung',
        generalInformation: 'Allgemeine Angaben zum Assistenten',
        inactive: 'inaktiv',
        intoCart: 'Warenkorb',
        label: 'Label',
        name: 'Benennung',
        notLinked: 'Assistent ist nicht vollständig verlinkt',
        result: 'Ergebnis',
        save: 'Speichern',
        searchChecks: 'Assistenten durchsuchen',
        searchArchive: 'Archiv durchsuchen',
        start: 'Starten',
        state: 'Status',
        title: 'Benennung des Assistenten',
        type: 'Typ des Assistenten',
        typeShort: 'Typ',
      },
      client: {
        cancel: 'Eingaben verwerfen',
        city: 'Ort',
        completePurchase: 'Buchung abschließen',
        confirmPassword: 'Passwort bestätigen',
        contactPerson: 'Ansprechpartner',
        data: 'Daten',
        email: 'E-Mail (Benutzername)',
        firstname: 'Vorname',
        lastname: 'Nachname',
        password: 'Passwort',
        passwordNoMatch: 'Das Passwort stimmt nicht überein.',
        phone: 'Telefon',
        save: 'Speichern',
        street: 'Straße/Haus-Nr.',
        zipCode: 'PLZ',
      },
      cms: {
        allChecks: 'Alle Assistenten',
        purchase: 'Assistenten buchen',
        checks: 'Assistenten',
        checkOverview: 'Assistenten',
        filterCategories: 'Kategorien',
        login: 'Kundenbereich',
        more: 'Mehr',
        teaser: 'Assistent Teaser',
        toApp: 'Kundenbereich',
        info: 'Info',
      },
      form: {
        allCategories: 'Alle Kategorien',
        checkLogo: 'Logo zum Assistent',
        loading: 'Lädt',
      },
      media: {
        addFile: 'Datei hinzufügen',
        cancel: 'Abbrechen',
        delete: 'Löschen',
        description: 'Beschreibung der Datei',
        edit: 'Bearbeiten',
        goToFiles: 'Zu den Dateien',
        hasFiles: 'Dieser Assistent enthält Dateien: ',
        name: 'Dateiname',
        save: 'Speichern',
      },
      orders: {
        activated: 'Aktiviert',
        activatedUntil: 'Aktiviert bis',
        check: 'Assistent',
        name: 'Benennung',
      },
      question: {
        addOption: 'Option hinzufügen',
        addition: 'Addition',
        cancel: 'Abbrechen',
        choose: 'Zielabfrage auswählen',
        condition: 'Option',
        conditionQuestion: 'Optionsvariante',
        division: 'Division',
        enterHint: 'Hinweistext eingeben',
        enterOutput: 'Ausgabetext eingeben',
        formulaQuestion: 'Berechnungsvariante',
        formulaGreater: 'Eingabe > Vergleichswert',
        formulaLess: 'Eingabe < Vergleichswert',
        formulaEqual: 'Eingabe = Vergleichswert',
        hint: 'Infotext',
        link: 'Verlinkung',
        multiplication: 'Multiplikation',
        no: 'Nein',
        operator: 'Operator',
        output: 'Ausgabetext',
        question: 'Abfrage',
        save: 'Speichern',
        subtraction: 'Subtraktion',
        type: 'Abfragetyp',
        yes: 'Ja',
        yesNoQuestion: 'Ja/Nein-Variante',
      },
      results: {
        customTitle: 'Benennung des Checks',
        createdAt: 'Erstellt am',
        creatorName: 'Ersteller',
        hint: 'Info',
        name: 'Benennung des Assistenten',
        question: 'Abfrage',
        type: 'Typ',
      },
      sidebar: {
        admins: 'Admins',
        results: 'Archiv',
        categories: 'Kategorien',
        checks: 'Assistenten',
        clients: 'Kunden',
        cms: 'Website',
        login: 'Login',
        media: 'Medien',
        menus: 'Menüs',
        orders: 'Bestellungen',
        pages: 'Seiten',
        settings: 'Einstellungen',
      },
      table: {
        actions: 'Funktionen',
        edit: 'Bearbeiten',
        view: 'Öffnen',
        duplicate: 'Duplizieren',
        delete: 'Löschen',
      },
      toolbar: {
        adminArea: 'Adminbereich',
        logout: 'Ausloggen',
        orders: 'Bestellungen',
        profile: 'Ihr Profil',
        purchase: 'Ihre gekauften Assistenten',
        userArea: 'Userbereich',
      },
    },
    pages: {
      admins: {
        add: 'Admin hinzufügen',
        cannotDelete: 'Sie können den einzigen Administrator nicht löschen. Bitte legen Sie vorher einen neuen an.',
        confirmDelete: 'Admin wirklich löschen?',
        createdAt: 'Angelegt',
        edit: 'Admin bearbeiten',
        email: 'Benutzername/E-Mail',
        search: 'Admins durchsuchen...',
        updatedAt: 'Bearbeitet',
        user: 'Benutzer',
      },
      categories: {
        add: 'Kategorie hinzufügen',
        delete: 'Kategorie wirklich löschen?',
        moveToFirstLevel: 'In oberste Ebene verschieben',
        name: 'Benennung',
        parent: 'Übergeordnete Kategorie',
        save: 'Speichern',
        private: 'Privat',
      },
      checks: {
        addFile: 'Datei hinzufügen',
        blocks: 'Abfrageblöcke',
        categoryNotFound: 'Kategorie nicht gefunden',
        check: 'Assistent',
        completed: 'Der Assistentendurchlauf ist abgeschlossen und wurde im Archiv gespeichert.',
        download: 'PDF-DOWNLOAD DES CHECKS',
        duplicate: 'Duplizieren',
        edit: 'Bearbeiten',
        files: 'Dateien',
        newTestRun: 'Neuen Testdurchlauf starten',
        test: 'Testen',
        testRun: 'Testdurchlauf',
      },
      clients: {
        add: 'Kunden hinzufügen',
        address: 'Anschrift',
        assignment: 'Assistentenzuordnung',
        checks: 'Assistenten',
        client: 'Kunde',
        confirmActivation: 'Kunde wirklich aktivieren?',
        confirmDeactivation: 'Kunde wirklich deaktivieren?',
        data: 'Kundendaten',
        edit: 'Kunden bearbeiten',
        orders: 'Bestellungen',
        search: 'Kunden durchsuchen...',
        state: 'Status',
      },
      cms: {
        addMenuItem: 'Menüpunkt hinzufügen',
        addPage: 'Seite hinzufügen',
        cancelPage: 'Seite verwerfen',
        chooseMenu: 'Menü auswählen',
        choosePage: 'Seite auswählen',
        menuItem: 'Menüpunkt',
        pageTitle: 'Seitentitel',
        save: 'Speichern',
      },
      orders: {
        company: 'Unternehmen',
        description: 'Kurzbeschreibung',
      },
      settings: {
        indexPage: 'Startseite',
        save: 'Speichern',
      },
      pdf: {
        answer: 'Antwort',
        annotation: 'Anmerkung des Erstellers',
        assignment: 'Zuweisung',
        createdAt: 'Erstellt am',
        compareValue: 'Vergleichswert',
        end: 'Ende',
        firstValue: 'Wert 1',
        formula: 'Formel',
        hint: 'Informationen zur Antwort',
        info: 'Info',
        infoQuestion: 'Informationen zur Abfrage',
        operator: 'Operator',
        secondValue: 'Wert 2',
        gt: 'Eingabe > Vergleichswert',
        lt: 'Eingabe < Vergleichswert',
        eq: 'Eingabe = Vergleichswert',
        description: 'Beschreibung des Assistenten',
        evaluation: 'Auswertung',
        result: 'Ergebnis',
        categories: 'Kategorien',
        documents: 'Dokumente',
        block: 'Abfrageblock',
        option: 'Option',
      },
      results: {
        add: 'Assistent hinzufügen',
        cancel: 'Abbrechen',
        check: 'Assistent',
        completed: 'Der Assistentendurchlauf ist abgeschlossen und wurde im Archiv gespeichert.',
        deleteAfterDownload: 'Assistentdurchlauf nach PDF- Download löschen.',
        download: 'PDF-DOWNLOAD DES CHECKS',
        editCustomDescription: 'Beschreibung eingeben',
        files: 'Dateien',
        noFiles: 'Keine Dateien',
        save: 'Speichern',
        start: 'Weiter',
      },
      signup: {
        cart: 'Warenkorb',
        completed: 'Bestellung abgeschlossen',
        continue: 'Weiter',
        data: 'Kundenzugang erstellen',
        emptyCart: 'Der Warenkorb ist leer',
        sentPurchaseHead: 'Bestellung wurde abgeschickt',
        sentPurchaseNextStep:
          'Im nächsten Schritt erhalten Sie von uns eine Rechnung für die Nutzung der gewählten Assistenten. Nach Zahlungseingang schalten wir Ihren Benutzer für die Verwendung der gewünschten Assistenten frei.',
        sentPurchaseContact:
          'Bei Fragen oder Problemen kontaktieren Sie uns bitte persönlich per E-Mail unter info@ibs-cepartner.de oder telefonisch unter 02244-903 22 66.',
        loginData: 'Login-Daten',
        infoText1: 'Bitte geben Sie Ihre gewünschten Login- und Kundendaten ein:',
        customerData: 'Kundendaten',
        company: 'Firma',
        firstnameContact: 'Vorname Kontaktperson',
        lastnameContact: 'Nachname Kontaktperson',
        phone: 'Telefonnummer',
      },
      login: {
        email: 'E-Mail-Adresse',
        password: 'Passwort',
        login: 'Anmelden',
      },
    },
  },
}

export default Strings
