import React from 'react'
const IconShield = props => (
  <svg {...props} width="13px" height="19px" viewBox="0 0 13 19" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-35.000000, -101.000000)" fill="#404944" fillRule="nonzero">
        <g id="Checks" transform="translate(0.000000, 85.000000)">
          <path
            d="M38.5743381,16 L35,19.5748376 L35,27.4014141 C35,30.5935903 37.1321139,31.5202705 41.0081851,33.9296392 C41.3084195,34.1164739 41.691081,34.1164739 41.9918149,33.9296392 C45.8678861,31.5202705 48,30.5935903 48,27.4014141 L48,16.6189525 C48,16.2772547 47.7227453,16 47.3810475,16 L38.5743381,16 Z M47,17 L47,27.4014141 C47,28.8688503 46.509639,29.7160244 45.1497479,30.7337951 C44.7408892,31.0397935 44.2548584,31.3617548 43.55645,31.7955279 C43.2983287,31.9558439 41.9669209,32.7684877 41.5,33.0579351 C41.0323308,32.7680309 39.7015988,31.9557989 39.44355,31.7955279 C38.7451416,31.3617548 38.2591108,31.0397935 37.8502521,30.7337951 C36.490361,29.7160244 36,28.8688503 36,27.4014141 L36,19.9890103 L38.9885926,17 L47,17 Z"
            id="Fill-26"
          ></path>
        </g>
      </g>
    </g>
  </svg>
)

export default IconShield
