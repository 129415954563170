import React from 'react'
const IconRemoveFromCart = props => (
  <svg {...props} width="35px" height="29px" viewBox="0 0 35 29" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1030.000000, -415.000000)">
        <g transform="translate(1030.000000, 416.000000)">
          <g transform="translate(0.000000, 12.000000)" fill="currentColor">
            <g>
              <path
                d="M3.90677064,1.85700025 L18.0547372,1.85700025 C18.4830814,1.85700025 18.8303231,2.2042419 18.8303231,2.63258619 C18.8264346,2.72758648 18.8264346,2.72758648 18.807166,2.82069341 L17.5308038,7.92614253 C17.4525849,8.23901808 17.1889114,8.47083994 16.8685961,8.50835581 L5.86644749,9.79694633 C5.49067309,9.84095767 5.13797855,9.60741567 5.03180558,9.24427561 L3.16234998,2.85023597 C3.04214532,2.4391038 3.27798867,2.00837019 3.68912085,1.88816553 C3.79646577,1.86224717 3.79646577,1.86224717 3.90677064,1.85700025 Z M4.83266014,3.3265315 L6.27777017,8.26919544 L16.2214372,7.10457611 L17.1659484,3.3265315 L4.83266014,3.3265315 Z"
                id="Rectangle"
                fillRule="nonzero"
              ></path>
              <path
                d="M0.5,1.29588294 L2.75394915,1.29588294 L2.75394915,1.29588294 L3.44357702,4.06021878 L4.13684546,6.3737611 C4.91402833,8.93521618 5.51940401,10.8085278 5.95414622,11.9968259 C6.09414367,12.3799312 6.45879973,12.6348586 6.86689142,12.6348586 L15.6461631,12.6348586 C15.875597,12.6348586 16.0755885,12.4787099 16.1312344,12.2561264 L16.2052051,11.9602435 C16.2721795,11.6923461 16.1092991,11.4208788 15.8414017,11.3539044 C15.8017414,11.3439894 15.7610148,11.3389757 15.7201339,11.3389757 L7.09418159,11.3389757 L7.09418159,11.3389757 L6.81694834,10.5422754 L6.61609339,9.94205521 C6.12846079,8.46586768 5.50806773,6.46006229 4.75568696,3.92669863 L3.82354171,0.373135614 C3.7658737,0.153291225 3.56718611,4.93182923e-16 3.33990401,0 L0.5,0 C0.223857625,5.07265313e-17 3.34394065e-14,0.223857625 3.37507799e-14,0.5 L3.37507799e-14,0.795882937 C3.37845976e-14,1.07202531 0.223857625,1.29588294 0.5,1.29588294 Z"
                id="Path-3"
                fillRule="nonzero"
              ></path>
              <circle cx="7.37769717" cy="14.796627" r="1.06026786"></circle>
              <circle cx="14.9762835" cy="14.796627" r="1.06026786"></circle>
            </g>
          </g>
          <g transform="translate(13.000000, 0.000000)" fill="currentColor" stroke="#FFFFFF" strokeWidth="2">
            <circle cx="10.5" cy="10.5" r="10.5"></circle>
          </g>
          <path
            d="M24.8269824,10.5001564 L26.817065,8.51007371 C27.0609783,8.26490954 27.0609783,7.86964485 26.817065,7.62573152 L26.3742685,7.182935 C26.1303551,6.93902167 25.7350905,6.93902167 25.4899263,7.182935 L23.4998436,9.17301765 L21.509761,7.182935 C21.2658477,6.93902167 20.8693321,6.93902167 20.6254188,7.182935 L20.1838731,7.62573152 C19.938709,7.86964485 19.938709,8.26490954 20.1838731,8.51007371 L22.1727049,10.5001564 L20.1838731,12.490239 C19.938709,12.7341523 19.938709,13.1306679 20.1838731,13.3745812 L20.6254188,13.8161269 C20.8693321,14.061291 21.2658477,14.061291 21.509761,13.8161269 L23.4998436,11.8272951 L25.4899263,13.8161269 C25.7350905,14.061291 26.1303551,14.061291 26.3742685,13.8161269 L26.817065,13.3745812 C27.0609783,13.1306679 27.0609783,12.7341523 26.817065,12.490239 L24.8269824,10.5001564 Z"
            id="Fill-1"
            fill="#FFFFFF"
          ></path>
        </g>
      </g>
    </g>
  </svg>
)

export default IconRemoveFromCart
